import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    cardComponents: any;
    bankComponents: any;
    otherComponents: any;
}

const PaymentMethodLayoutWrapper = (props: IProps) => {
    const [banklinkChecked, setBanklinkChecked] = useState(true);
    const [bankcardChecked, setBankcardChecked] = useState(true);
    const [otherPaymentMethodsChecked, setOtherPaymentMethodsChecked] = useState(false);

    const { cardComponents, bankComponents, otherComponents } = props;

    const { t } = useTranslation();

    return (
        <ul className="list-accordions compact">
            <li className="list-accordions__item">
                <input
                    type="checkbox"
                    id="banklink"
                    checked={banklinkChecked}
                    onChange={() => setBanklinkChecked(!banklinkChecked)}
                />
                <h3>
                    <label htmlFor="banklink">{t('Pay with bank link')}</label>
                </h3>
                <div className="list-accordions__item__content">
                    <ul className="banklinks layout-default">{bankComponents}</ul>
                </div>
            </li>

            <li className="list-accordions__item">
                <input
                    type="checkbox"
                    id="bankcard"
                    checked={bankcardChecked}
                    onChange={() => setBankcardChecked(!bankcardChecked)}
                />
                <h3>
                    <label htmlFor="bankcard">{t('Pay with card')}</label>
                </h3>
                <div className="list-accordions__item__content">
                    <ul className="banklinks layout-default">{cardComponents}</ul>
                </div>
            </li>

            <li className="list-accordions__item">
                <input
                    type="checkbox"
                    id="other"
                    checked={otherPaymentMethodsChecked}
                    onChange={() => setOtherPaymentMethodsChecked(!otherPaymentMethodsChecked)}
                />
                <h3>
                    <label htmlFor="other">{t('Other payment methods')}</label>
                </h3>
                <div className="list-accordions__item__content">
                    <ul className="banklinks layout-vertical">{otherComponents}</ul>
                </div>
            </li>
        </ul>
    );
};

export default PaymentMethodLayoutWrapper;
